import React from 'react';
import {
  ArrayField,
  Datagrid,
  ListButton,
  SimpleShowLayout,
  Show,
  ShowActionsProps,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TitleProps,
  TopToolbar,
  useShowController,
  useRecordContext,
  Record
} from 'react-admin';
import { CreateFundButton } from './buttons/CreateFundButton';
import { CreateLPButton } from './buttons/CreateLPButton';
import { FundDashboardButton } from '../../funds/FundDashboardButton';
import { Box } from '@material-ui/core';
import { RepresentativeShowButton } from '../../representatives/RepresentativeShowButton';
import { CreateRepresentativeButton } from './buttons/CreateRepresentativeButton';
import { CurrencyField } from '../../util/fields/CurrencyField';
import { useHistory } from 'react-router-dom';
import { LimitedPartnerShowButton } from '../../limited_partners/LimitedPartnerShowButton';
import { Button, useMutation } from 'react-admin';


const DemoInitButton :  React.FC = () => {
  const record = useRecordContext();
  const [approve, { loading }] = useMutation({
    type: 'update',
    resource: 'demo',
    payload: { id: record.id }
  });
  return <Button label="Demo" onClick={approve} disabled={loading} />;
};

const CompanyDashboardActions: React.FC<ShowActionsProps> = ({
  basePath,
}: ShowActionsProps) => {
  return (
    <TopToolbar>
      <DemoInitButton />
      <ListButton basePath={basePath} />
    </TopToolbar>
  );
};

const CompanyTitle: React.FC<TitleProps> = ({ record }) => {
  return <span>{record ? `${record.name}` : 'Company'} dashboard</span>;
};

export const transformLimitedPartnersInPlace = (record: Record): void => {
  record['limitedPartners'] = record.limitedPartnerCompanies
    .map((company: Record) => ({ ...company, type: 'company' }))
    .concat(
      record.limitedPartnerPeople.map((person: Record) => ({
        ...person,
        name: `${person.firstName} ${person.lastName}`,
        type: 'person',
      }))
    );
}

export const CompanyDashboard: React.FC<ShowProps> = (props: ShowProps) => {
  const { record } = useShowController(props);
  const history = useHistory();

  let layout = (
    <SimpleShowLayout>
      <div />
    </SimpleShowLayout>
  );

  if (record != undefined) {
    transformLimitedPartnersInPlace(record);

    layout = (
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant='fullWidth' />}>
        <Tab label='Company information' style={{ textTransform: 'none' }}>
          <TextField source='name' />
          <TextField source='address' />
          <TextField source='registryCode' />
        </Tab>
        <Tab label='Funds' style={{ textTransform: 'none' }}>
          <CreateFundButton />
          <Box justifyContent='space-evenly'>
            <ArrayField source='funds' fieldKey='id'>
              <Datagrid
                rowClick={(id, _, fundRecord) => {
                  history.push({
                    pathname: '/funds/' + fundRecord?.id + '/show',
                    state: {
                      record: {
                        fundId: fundRecord?.id,
                        limitedPartnerPeople: record.limitedPartnerPeople,
                        limitedPartnerCompanies: record.limitedPartnerCompanies,
                        companyId: props.id as string,
                      },
                    },
                  });
                  return '/funds/' + id + '/show';
                }}
              >
                <TextField source='name' />
                <CurrencyField source='fundSize' label='Fund size' />
                <TextField source='symbol' />
                <TextField source='serviceType' />
                <FundDashboardButton
                  companyId={props.id as string}
                  limitedPartnerPeople={record.limitedPartnerPeople}
                  limitedPartnerCompanies={record.limitedPartnerCompanies}
                />
              </Datagrid>
            </ArrayField>
          </Box>
        </Tab>
        <Tab label='Limited partners' style={{ textTransform: 'none' }}>
          <CreateLPButton />
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-evenly'
            alignItems='stretch'
            padding={2}
            style={{ gap: '5vh' }}
          >
            <ArrayField source='limitedPartners' addLabel fieldKey='id'>
              <Datagrid>
                <TextField source='email' />
                <TextField source='name' />
                <TextField source='registryCode' />
                <TextField source='amlStatus' />
                <TextField source='veriffStatus' />
                <LimitedPartnerShowButton companyId={props.id as string} />
              </Datagrid>
            </ArrayField>
            {/* <ArrayField
              source='limitedPartnerCompanies'
              label='Companies'
              fieldKey='id'
            >
              <Datagrid>
                <TextField source='name' />
                <TextField source='registryCode' />
                <TextField source='amlStatus' />
                <LimitedPartnerCompanyShowButton
                  companyId={props.id as string}
                />
              </Datagrid>
            </ArrayField>*/}
          </Box>
        </Tab>
        <Tab label='Representatives' style={{ textTransform: 'none' }}>
          <CreateRepresentativeButton />
          <Box justifyContent='space-evenly'>
            <ArrayField
              source='representatives'
              label='Representatives'
              fieldKey='id'
            >
              <Datagrid>
                <TextField source='email' />
                <TextField source='firstName' />
                <TextField source='lastName' />
                <TextField source='phone' />
                <RepresentativeShowButton companyId={props.id as string} />
              </Datagrid>
            </ArrayField>
          </Box>
        </Tab>
      </TabbedShowLayout>
    );
  }

  return (
    <Show
      {...props}
      title={<CompanyTitle />}
      actions={<CompanyDashboardActions />}
    >
      {layout}
    </Show>
  );
};
