/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAdmin,
    ApiAdminFromJSON,
    ApiAdminToJSON,
    ApiCreateAdminRequest,
    ApiCreateAdminRequestFromJSON,
    ApiCreateAdminRequestToJSON,
    ApiRole,
    ApiRoleFromJSON,
    ApiRoleToJSON,
} from '../models';

export interface AddAdminRequest {
    apiCreateAdminRequest: ApiCreateAdminRequest;
}

/**
 * 
 */
export class AdminsApi extends runtime.BaseAPI {

    /**
     * creates a new admin for a company
     */
    async addAdminRaw(requestParameters: AddAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAdmin>> {
        if (requestParameters.apiCreateAdminRequest === null || requestParameters.apiCreateAdminRequest === undefined) {
            throw new runtime.RequiredError('apiCreateAdminRequest','Required parameter requestParameters.apiCreateAdminRequest was null or undefined when calling addAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCreateAdminRequestToJSON(requestParameters.apiCreateAdminRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAdminFromJSON(jsonValue));
    }

    /**
     * creates a new admin for a company
     */
    async addAdmin(requestParameters: AddAdminRequest, initOverrides?: RequestInit): Promise<ApiAdmin> {
        const response = await this.addAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns logged in admin role
     */
    async getRolesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiRole>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiRoleFromJSON));
    }

    /**
     * returns logged in admin role
     */
    async getRoles(initOverrides?: RequestInit): Promise<Array<ApiRole>> {
        const response = await this.getRolesRaw(initOverrides);
        return await response.value();
    }

}
