import React from "react";
import {
  email,
  required,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

import { phoneNumberValidator } from "../util/phoneNumber";

export const CompanyRepresentativeForm: React.FC = () => (
  <ArrayInput
    source="representatives"
    label="Representatives"
    validate={required("At least one representative is required")}
  >
    <SimpleFormIterator disableReordering>
      <TextInput
        source="email"
        label="Email"
        validate={[
          required("The email of representative is required"),
          email("Invalid email"),
        ]}
      />
      <TextInput
        source="firstName"
        label="First name"
        validate={[required("First name is required")]}
      />
      <TextInput
        source="lastName"
        label="Last name"
        validate={[required("Last name is required")]}
      />
      <TextInput
        source="phone"
        label="Phone"
        validate={[phoneNumberValidator]}
      />
      <TextInput
        source="legalPowers"
        label="Legal powers"
        validate={[required("Legal powers is a required field")]}
      />
    </SimpleFormIterator>
  </ArrayInput>
);
