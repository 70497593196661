/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceType {
    FundStructure = 'FundStructure',
    FundStructurePortfolioManagement = 'FundStructurePortfolioManagement'
}

export function ServiceTypeFromJSON(json: any): ServiceType {
    return ServiceTypeFromJSONTyped(json, false);
}

export function ServiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceType {
    return json as ServiceType;
}

export function ServiceTypeToJSON(value?: ServiceType | null): any {
    return value as any;
}

