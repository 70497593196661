import React, { ReactElement } from 'react';
import './App.css';
import { buildAuthProvider } from 'react-admin-amplify';
import { Admin, Resource, UserIdentity } from 'react-admin';
import { Amplify, Hub } from '@aws-amplify/core';
import {Auth} from '@aws-amplify/auth'
import awsmobile from './awsmobile';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from '@aws-amplify/ui-react';
import { CompanyList } from './companies/CompanyList';
import { CompanyCreate } from './companies/CompanyCreate';
import dataProvider from './data/dataProvider';
import { adminsApi, RoleType } from './apis';
import { LimitedPartnersCreate } from './limited_partners/LimitedPartnersCreate';
import { FundCreate } from './funds/FundCreate';
import { FundEdit } from './funds/FundEdit';
import { FundDashboard } from './funds/FundDashboard';
import { CompanyDashboard } from './companies/dashboard/CompanyDashboard';
import { LimitedPartnerPersonShow } from './limited_partners/person/LimitedPartnerPersonShow';
import { LimitedPartnerPersonEdit } from './limited_partners/person/LimitedPartnerPersonEdit';
import { LimitedPartnerCompanyEdit } from './limited_partners/company/LimitedPartnerCompanyEdit';
import { LimitedPartnerCompanyShow } from './limited_partners/company/LimitedPartnerCompanyShow';
import { RepresentativeShow } from './representatives/RepresentativeShow';
import { RepresentativeCreate } from './representatives/RepresentativeCreate';

Amplify.configure(awsmobile);

const listener = async (data: { payload: { event: any } }) => {
  if (data.payload.event === 'signIn') {
    window.location.href = '/';
  }
};

Hub.listen('auth', listener);

export const renderResourcesBasedOnRole = async (): Promise<
  Array<ReactElement> | undefined
> => {
  const roleTypes = (await adminsApi.getRoles()).map((it) => it.type);
  if (
    roleTypes.includes(RoleType.Admin) ||
    roleTypes.includes(RoleType.Owner)
  ) {
    return [
      <Resource
        key={1}
        name='companies'
        list={CompanyList}
        show={CompanyDashboard}
        create={CompanyCreate}
        options={{ label: 'Companies' }}
      />,
      <Resource
        key={2}
        name='limited-partner-people'
        show={LimitedPartnerPersonShow}
        edit={LimitedPartnerPersonEdit}
        create={LimitedPartnersCreate}
      />,
      <Resource
        key={3}
        name='limited-partner-companies'
        create={LimitedPartnersCreate}
        show={LimitedPartnerCompanyShow}
        edit={LimitedPartnerCompanyEdit}
      />,
      <Resource
        key={4}
        name='funds'
        show={FundDashboard}
        create={FundCreate}
        edit={FundEdit}
      />,
      <Resource
        key={5}
        name='representatives'
        show={RepresentativeShow}
        create={RepresentativeCreate}
      />,
    ];
  }
};

const getIdentity = async (): Promise<UserIdentity> => {
  const authenticatedUser = await Auth.currentAuthenticatedUser();
  return {
    id: authenticatedUser.username,
    fullName: authenticatedUser.username,
  };
};

const App = (): React.ReactElement => {
  return (
    <AmplifyAuthenticator usernameAlias='email'>
      <AmplifySignIn slot='sign-in' usernameAlias='email' />
      <AmplifySignUp
        slot='sign-up'
        usernameAlias='email'
        formFields={[{ type: 'email' }, { type: 'password' }]}
      />
      <Admin
        authProvider={{
          ...buildAuthProvider(),
          getPermissions: () => adminsApi.getRoles(),
          getIdentity: getIdentity
        }}
        dataProvider={dataProvider}
        loginPage={false}
      >
        {renderResourcesBasedOnRole}
      </Admin>
    </AmplifyAuthenticator>
  );
};

export default App;
