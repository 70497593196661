import React from 'react';
import { FieldProps, Record, useRecordContext } from 'react-admin';
import { format } from '../currency';
import { Box } from '@material-ui/core';

const renderValue = (record: Record, source: string): string => {
  const value = record && record[source];
  return value && format(value.toString());
};

export const CurrencyField: React.FC<FieldProps> = (props) => {
  const record = useRecordContext(props);

  return <Box>{renderValue(record, props.source as string)}</Box>;
};
