import React from 'react';
import { ResourceComponentProps } from 'ra-core';
import {
  Datagrid,
  List,
  ShowButton,
  ShowButtonProps,
  TextField,
} from 'react-admin';

const CompanyDashboardButton: React.FC<ShowButtonProps> = ({
  record,
}: ShowButtonProps) => (
  <ShowButton
    label='View'
    record={record}
    style={{ textTransform: 'none' }}
  />
);

export const CompanyList: React.FC<ResourceComponentProps> = (
  props: ResourceComponentProps
) => {
  return (
    <List {...props} exporter={false}>
      <Datagrid rowClick='show'>
        <TextField source='name' />
        <TextField source='registryCode' />
        <CompanyDashboardButton />
      </Datagrid>
    </List>
  );
};
