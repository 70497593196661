import React from 'react';
import {
  Edit,
  EditProps,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  EditActionsProps,
  ArrayInput,
  SimpleFormIterator,
  useRedirect,
  DateInput,
  TabbedForm,
  FormTab,
  TabbedFormTabs,
} from 'react-admin';
import { ServiceType } from '../api';
import { FundDashboardButton } from './FundDashboardButton';
import './FundEdit.css';
import { InvestmentsForm } from './InvestmentsForm';
import { CurrencyInput } from '../util/inputs/CurrencyInput';
import { fundMutationTransform } from './fundMutationTransform';
import { useLocationState } from "../location/useLocationState";

interface FundEditActionsProps extends EditActionsProps {
  companyId: string;
  limitedPartnerPeople: any[];
  limitedPartnerCompanies: any[];
}

const FundEditActions: React.FC<FundEditActionsProps> = (props) => {
  return (
    <TopToolbar {...props}>
      <FundDashboardButton
        companyId={props.companyId}
        record={props.data}
        limitedPartnerPeople={props.limitedPartnerPeople}
        limitedPartnerCompanies={props.limitedPartnerCompanies}
      />
    </TopToolbar>
  );
};

export const FundEdit: React.FC<EditProps> = (props: EditProps) => {
  const redirect = useRedirect();
  const location = useLocationState();
  const state = location.state.record;
  const companyId = state.companyId;
  const limitedPartnerPeople = state.limitedPartnerPeople;
  const limitedPartnerCompanies = state.limitedPartnerCompanies;
  return (
    <Edit
      {...props}
      title='Edit fund'
      undoable={false}
      onSuccess={() => {
        redirect('show', '/funds', props.id, {}, location.state);
      }}
      transform={fundMutationTransform}
      actions={
        <FundEditActions
          companyId={companyId}
          limitedPartnerPeople={limitedPartnerPeople}
          limitedPartnerCompanies={limitedPartnerCompanies}
          {...props}
        />
      }
    >
      <TabbedForm
        syncWithLocation={false}
        tabs={<TabbedFormTabs variant='fullWidth' />}
      >
        <FormTab label='Fund info' classes={{ textTransform: 'none' }}>
          <TextInput
            source='name'
            validate={[required('Fund name is required')]}
          />
          <CurrencyInput source='fundSize' validate={[required("Fund size is required")]} />
          <SelectInput
            source='serviceType'
            choices={[
              { id: ServiceType.FundStructure, name: 'Fund Structure' },
              {
                id: ServiceType.FundStructurePortfolioManagement,
                name: 'Fund Structure Portfolio Management',
              },
            ]}
            validate={[required('Service type is required')]}
          />
        </FormTab>
        <FormTab label='Investments'>
          <InvestmentsForm />
        </FormTab>
        <FormTab label='Registry'>
          <ArrayInput source='personRegistry' fieldKey='id'>
            <SimpleFormIterator disableReordering>
              <SelectInput
                label='Person'
                source='personId'
                choices={
                  limitedPartnerPeople &&
                  limitedPartnerPeople.map((it: any) => {
                    return {
                      id: it.id,
                      name: it.email,
                    };
                  })
                }
              />
              {registryFormCommonInputs}
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source='companyRegistry' fieldKey='id'>
            <SimpleFormIterator disableReordering>
              <SelectInput
                label='Company'
                source='companyId'
                choices={
                  limitedPartnerCompanies &&
                  limitedPartnerCompanies.map((it: any) => {
                    return {
                      id: it.id,
                      name: it.name,
                    };
                  })
                }
              />
              <TextInput source='contactEmail' label='Contact email' />
              <TextInput source='residency' label='Residency' />
              {registryFormCommonInputs}
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const registryFormCommonInputs = [
  <TextInput key='taxResidency' source='taxResidency' label='Tax residency' />,
  <TextInput key='address' source='address' label='Address' />,
  <CurrencyInput
    key='totalCommitment'
    source='totalCommitment'
    label='Total commitment'
  />,
  <CurrencyInput
    key='paidInCapital'
    source='paidInCapital'
    label='Paid in capital'
  />,
  <TextInput key='bankAccount' source='bankAccount' label='Bank account' />,
  <DateInput
    key='agreementSigningDate'
    parse={(value: any) => new Date(Date.parse(value))}
    source='agreementSigningDate'
    label='Signing date'
  />,
  <DateInput
    key='firstClosingDate'
    parse={(value: any) => new Date(Date.parse(value))}
    source='firstClosingDate'
    label='First closing date'
  />,
];
