/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AmlStatus {
    AwaitingPrefill = 'AWAITING_PREFILL',
    Prefilled = 'PREFILLED',
    AwaitingResponse = 'AWAITING_RESPONSE',
    Approved = 'APPROVED'
}

export function AmlStatusFromJSON(json: any): AmlStatus {
    return AmlStatusFromJSONTyped(json, false);
}

export function AmlStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmlStatus {
    return json as AmlStatus;
}

export function AmlStatusToJSON(value?: AmlStatus | null): any {
    return value as any;
}

