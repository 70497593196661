import { Button, ShowButtonProps } from 'react-admin';
import React from 'react';
import { Visibility } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export interface RepresentativeShowButtonProps extends ShowButtonProps {
  companyId: string;
}

export const RepresentativeShowButton: React.FC<
  RepresentativeShowButtonProps
> = ({ record, companyId }: RepresentativeShowButtonProps) => (
  <Button
    component={Link}
    style={{ textTransform: 'none' }}
    to={{
      pathname: '/representatives/' + record?.id + '/show',
      state: { record: { companyId } },
    }}
    label='View'
  >
    <Visibility />
  </Button>
);
