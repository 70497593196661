import { InputAdornment } from "@material-ui/core";
import React from "react";
import { InputProps, TextInput } from "react-admin";
import { currencyValidator } from "../currency";

export const CurrencyInput: React.FC<InputProps> = (props) => {

  let validate
  if (props.validate != null) {
    validate = [currencyValidator].concat(props.validate)
  } else {
    validate = [currencyValidator]
  }

return (
    <TextInput
      {...props}
      format={(value: any) => value || ""}
      validate={validate}
      InputProps={{startAdornment: <InputAdornment position="start">€</InputAdornment>}}
    />
  );
};
