import { Button, useRecordContext } from 'react-admin';
import { Add } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export const CreateLPButton: React.FC = () => {
  const record = useRecordContext();
  return (
    <Button
      style={{
        textTransform: 'none',
        width: '100%',
        height: '5vh',
        margin: '2vh 0 2vh 0',
      }}
      variant='contained'
      component={Link}
      to={{
        pathname: '/limited-partner-people/create',
        state: { record: { companyId: record.id } },
      }}
      label='Add limited partners'
    >
      <Add />
    </Button>
  );
};
