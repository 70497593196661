/* eslint no-case-declarations: 0 */

import { DataProvider } from "react-admin";
import {
  companiesApi,
  fundsApi,
  representativesApi,
  limitedPartnersApi,
  ownerApi,
  demoApi,
} from "../apis";

// @ts-ignore
const dataProvider: DataProvider = {
  // @ts-ignore
  create: async (resource, params) => {
    switch (resource) {
      case "limited-partner-people":
        const createLimitedPartnerPeopleResponse =
          await limitedPartnersApi.addLimitedPartnerPeople({
            apiCreateLimitedPartnerPersonRequest: params.data.lps,
            companyId: params.data.companyId,
          });

        return {
          data: { ...createLimitedPartnerPeopleResponse, id: "" },
        };
      case "limited-partner-companies":
        const createLimitedPartnerCompaniesResponse =
          await limitedPartnersApi.addLimitedPartnerCompanies({
            companyId: params.data.companyId,
            apiCreateCompanyRequest: params.data.companies,
          });

        return {
          data: { ...createLimitedPartnerCompaniesResponse, id: "" },
        };
      case "companies":
        const apiCompany = await ownerApi.registerCompany({
          apiCreateCompanyRequest: {
            ...params.data,
          },
        });

        return {
          data: apiCompany,
        };
      case "funds":
        const apiFunds = await fundsApi.createFund({
          id: params.data.companyId,
          apiUpsertFundRequest: {
            ...params.data,
          },
        });

        return {
          data: apiFunds,
        };
      case "representatives":
        const createRepresentativesResponse =
          await representativesApi.addRepresentative({
            companyId: params.data.companyId,
            apiCreateAdminRequest: params.data.representatives,
          });

        return {
          data: {
            ...createRepresentativesResponse,
            id: "",
          },
        };
      default:
        throw Error(
          `Operation create not implemented for resource ${resource}`
        );
    }
  },
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: async (resource, params) => {
    if (resource === "companies") {
      const apiCompanies = await companiesApi.listCompanies();

      return {
        data: apiCompanies,
        total: apiCompanies.length,
      };
    } else {
      throw Error(`Operation getList not implemented for resource ${resource}`);
    }
  },
  // @ts-ignore
  getOne: async (resource, params) => {
    switch (resource) {
      case "companies":
      case "limited-partner-companies":
        const apiCompany = await companiesApi.getCompany({
          companyId: params.id as string,
        });

        return {
          data: apiCompany,
        };
      case "funds":
        const apiFund = await fundsApi.getFund({
          fundId: params.id as string,
        });

        return {
          data: apiFund,
        };
      case "limited-partner-people":
        const apiInvestorProfile =
          await limitedPartnersApi.getLimitedPartnerPerson({
            personId: params.id as string,
          });

        return {
          data: apiInvestorProfile,
        };
      case "representatives":
        const apiInvestor = await representativesApi.getRepresentative({
          personId: params.id as string,
        });

        return {
          data: apiInvestor,
        };
      default:
        throw Error(
          `Operation getOne not implemented for resource ${resource}`
        );
    }
  },
  // @ts-ignore
  update: async (resource, params) => {
    switch (resource) {
      case "funds":
        const apiFund = await fundsApi.updateFund({
          fundId: params.data.id,
          apiUpsertFundRequest: { ...params.data },
        });

        return {
          data: apiFund,
        };

      case "limited-partner-people":
        const apiInvestorProfile =
          await limitedPartnersApi.updateLimitedPartnerPerson({
            personId: params.data.id,
            apiUpdateLimitedPartnerPersonRequest: { ...params.data },
          });

        return {
          data: apiInvestorProfile,
        };
      case "limited-partner-companies":
        const apiCompanies = await companiesApi.updateCompany({
          companyId: params.data.id,
          apiUpdateCompanyRequest: {
            companyData: {
              name: params.data.name,
              registryCode: params.data.registryCode,
              amlStatus: params.data.amlStatus,
            },
            representatives: [],
          },
        });
        return {
          data: apiCompanies,
        };
      case "demo":
        await demoApi.refreshCompanyWithDemo({
          companyId: String(params.id),
          body: true,
        });
        return {
          data: {
            id: params.id,
          },
        };
      default:
        throw Error(
          `Operation update not implemented for resource ${resource}`
        );
    }
  },
};

export default dataProvider;
