import { Button, ShowButtonProps } from 'react-admin';
import React from 'react';
import { Visibility } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export interface FundDashboardButtonProps extends ShowButtonProps {
  companyId: string;
  limitedPartnerPeople: any[];
  limitedPartnerCompanies: any[];
}

export const FundDashboardButton: React.FC<FundDashboardButtonProps> = ({
  record,
  companyId,
  limitedPartnerCompanies,
  limitedPartnerPeople,
}: FundDashboardButtonProps) => (
  <Button
    component={Link}
    style={{ textTransform: 'none' }}
    to={{
      pathname: '/funds/' + record?.id + '/show',
      state: {
        record: {
          fundId: record?.id,
          limitedPartnerPeople,
          limitedPartnerCompanies,
          companyId,
        },
      },
    }}
    label='View'
  >
    <Visibility />
  </Button>
);
