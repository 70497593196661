import React from 'react';
import {
  ArrayInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { InstrumentType } from '../api';
import { CurrencyInput } from '../util/inputs/CurrencyInput';

export const InvestmentsForm: React.FC = () => (
  <ArrayInput source='investments'>
    <SimpleFormIterator disableReordering>
      <TextInput
        source='name'
        label='Name'
        validate={[required('Name is required')]}
      />
      <CurrencyInput source='fairValue' label='Fair value' />
      <TextInput
        source='sector'
        label='Sector'
        validate={[required('Sector is required')]}
      />
      <SelectInput
        source='instrument'
        label='Instrument type'
        choices={[
          { id: InstrumentType.Equity, name: 'Equity' },
          { id: InstrumentType.Convertible, name: 'Convertible' },
          { id: InstrumentType.Option, name: 'Option' },
          { id: InstrumentType.Warrant, name: 'Warrant' },
          { id: InstrumentType.Loan, name: 'Loan' },
        ]}
        validate={[required('Instrument type is required')]}
      />
    </SimpleFormIterator>
  </ArrayInput>
);
