import {
  Configuration,
  CompaniesApi,
  AdminsApi,
  Middleware,
  OwnerApi,
  FundsApi,
  LimitedPartnersApi,
  RepresentativesApi,
  DemoApi,
} from "./api";
import { Auth } from "@aws-amplify/auth";

const basePath = "/api/v1";

const middleware: Middleware = {
  pre: async (context) => {
    const cognitoUserSession = await Auth.currentSession();
    const idToken = cognitoUserSession.getIdToken();
    const jwtToken = idToken.getJwtToken();

    const newHeaders = new Headers(context.init.headers);
    newHeaders.set("Authorization", `Bearer ${jwtToken}`);

    const newRequest = { ...context.init, headers: newHeaders };

    return { url: context.url, init: newRequest };
  },
};

export const configuration = new Configuration({
  basePath,
  middleware: [middleware],
});

export const limitedPartnersApi = new LimitedPartnersApi(configuration);
export const companiesApi = new CompaniesApi(configuration);
export const adminsApi = new AdminsApi(configuration);
export const ownerApi = new OwnerApi(configuration);
export const fundsApi = new FundsApi(configuration);
export const representativesApi = new RepresentativesApi(configuration);
export const demoApi = new DemoApi(configuration);
export * from "./api";
