/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCompanyRegistry,
    ApiCompanyRegistryFromJSON,
    ApiCompanyRegistryFromJSONTyped,
    ApiCompanyRegistryToJSON,
    ApiInvestment,
    ApiInvestmentFromJSON,
    ApiInvestmentFromJSONTyped,
    ApiInvestmentToJSON,
    ApiPersonRegistry,
    ApiPersonRegistryFromJSON,
    ApiPersonRegistryFromJSONTyped,
    ApiPersonRegistryToJSON,
    ServiceType,
    ServiceTypeFromJSON,
    ServiceTypeFromJSONTyped,
    ServiceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiUpsertFundRequest
 */
export interface ApiUpsertFundRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiUpsertFundRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ApiUpsertFundRequest
     */
    fundSize?: number;
    /**
     * 
     * @type {ServiceType}
     * @memberof ApiUpsertFundRequest
     */
    serviceType: ServiceType;
    /**
     * 
     * @type {string}
     * @memberof ApiUpsertFundRequest
     */
    symbol?: string;
    /**
     * 
     * @type {Array<ApiInvestment>}
     * @memberof ApiUpsertFundRequest
     */
    investments: Array<ApiInvestment>;
    /**
     * 
     * @type {Array<ApiCompanyRegistry>}
     * @memberof ApiUpsertFundRequest
     */
    companyRegistry: Array<ApiCompanyRegistry>;
    /**
     * 
     * @type {Array<ApiPersonRegistry>}
     * @memberof ApiUpsertFundRequest
     */
    personRegistry: Array<ApiPersonRegistry>;
}

export function ApiUpsertFundRequestFromJSON(json: any): ApiUpsertFundRequest {
    return ApiUpsertFundRequestFromJSONTyped(json, false);
}

export function ApiUpsertFundRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiUpsertFundRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'fundSize': !exists(json, 'fundSize') ? undefined : json['fundSize'],
        'serviceType': ServiceTypeFromJSON(json['serviceType']),
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'investments': ((json['investments'] as Array<any>).map(ApiInvestmentFromJSON)),
        'companyRegistry': ((json['companyRegistry'] as Array<any>).map(ApiCompanyRegistryFromJSON)),
        'personRegistry': ((json['personRegistry'] as Array<any>).map(ApiPersonRegistryFromJSON)),
    };
}

export function ApiUpsertFundRequestToJSON(value?: ApiUpsertFundRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fundSize': value.fundSize,
        'serviceType': ServiceTypeToJSON(value.serviceType),
        'symbol': value.symbol,
        'investments': ((value.investments as Array<any>).map(ApiInvestmentToJSON)),
        'companyRegistry': ((value.companyRegistry as Array<any>).map(ApiCompanyRegistryToJSON)),
        'personRegistry': ((value.personRegistry as Array<any>).map(ApiPersonRegistryToJSON)),
    };
}

