import React, { useState } from 'react';
import { LimitedPartnerType } from '../api';
import { Box, InputLabel, MenuItem, Select } from '@material-ui/core';
import { CreateProps, Button } from 'react-admin';
import { useLocation } from 'react-router';
import { NaturalPersonCreate } from './person/NaturalPersonCreate';
import { KeyboardBackspace } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { LimitedPartnerCompanyCreate } from './company/LimitedPartnerCompanyCreate';

export const LimitedPartnersCreate: React.FC<CreateProps> = (props) => {
  const location = useLocation();
  const companyId =
    location.state && (location.state as any).record
      ?
      (location.state as any).record.companyId
      : undefined;

  const redirect = companyId ? `/companies/${companyId}/show/2` : false;

  const [selectedLPType, setSelectedLPType] = useState<
    LimitedPartnerType | 'Choose LP type'
  >('Choose LP type');

  const naturalPersonTransform = (data: any) => {
    return { ...data, companyId };
  };

  const companyTransform = (data: any) => {
    data.companies.map((it: any) => {
      it['companyData'] = {
        name: it.companyName,
        registryCode: it.registryCode,
      };
    });
    return { ...data, companyId };
  };

  const renderCreateComponent = () => {
    if (selectedLPType === LimitedPartnerType.NaturalPerson) {
      return (
        <NaturalPersonCreate
          {...props}
          naturalPersonTransform={naturalPersonTransform}
          redirect={redirect}
        />
      );
    } else if (selectedLPType === LimitedPartnerType.Company) {
      return (
        <LimitedPartnerCompanyCreate
          {...props}
          companyTransform={companyTransform}
          redirect={redirect}
        />
      );
    } else {
      return undefined;
    }
  };

  return (
    <Box display='flex' flexDirection='column' width='80vh' marginTop={'0.5vw'}>
      <Box display='flex' flexDirection='row'>
        <Box>
          <InputLabel id='lp-type-label'>LP type</InputLabel>
          <Select
            labelId='lp-type-label'
            id='lp-type-select'
            value={selectedLPType}
            label='LP type'
            style={{ width: '40vh' }}
            onChange={(event) =>
              setSelectedLPType(event.target.value as LimitedPartnerType)
            }
          >
            <MenuItem value={'Choose LP type'} style={{ minHeight: '36px' }}>
              {'Choose LP type'}
            </MenuItem>
            <MenuItem value={LimitedPartnerType.NaturalPerson}>
              Natural person
            </MenuItem>
            <MenuItem value={LimitedPartnerType.Company}>Company</MenuItem>
          </Select>
        </Box>
        <Button
          style={{ textTransform: 'none', marginLeft: 'auto' }}
          component={Link}
          to={{
            pathname: `/companies/${companyId}/show/2`,
          }}
          label='Back to company'
        >
          <KeyboardBackspace />
        </Button>
      </Box>
      {renderCreateComponent()}
    </Box>
  );
};
