import React from 'react';
import {
  ArrayInput,
  Create,
  CreateProps,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  RedirectionSideEffect,
  Record as RaRecord,
  SelectInput,
} from 'react-admin';
import { AmlStatus } from '../../api';

export interface NaturalPersonCreateProps extends CreateProps {
  naturalPersonTransform: (data: RaRecord) => RaRecord | Promise<RaRecord>;
  redirect: RedirectionSideEffect;
}

export const NaturalPersonCreate: React.FC<NaturalPersonCreateProps> = (
  props: NaturalPersonCreateProps
) => {
  return (
    <Create
      {...props}
      basePath=''
      resource='limited-partner-people'
      transform={props.naturalPersonTransform}
      title='Add natural person LP'
    >
      <SimpleForm redirect={props.redirect}>
        <ArrayInput source='lps' label='Limited partners'>
          <SimpleFormIterator disableReordering>
            <TextInput
              label='Email'
              source='email'
              validate={[required('Email is required')]}
            />
            <TextInput
              label='First name'
              source='firstName'
              validate={[required('First name is required')]}
            />
            <TextInput
              label='Last name'
              source='lastName'
              validate={[required('Last name is required')]}
            />
            <TextInput
              label='Residency'
              source='residency'
              validate={[required('Residency is required')]}
            />
            <SelectInput
              label='AML status'
              source='amlStatus'
              choices={[
                { id: AmlStatus.AwaitingPrefill, name: 'Awaiting Prefill' },
                { id: AmlStatus.Prefilled, name: 'Prefilled' },
                { id: AmlStatus.AwaitingResponse, name: 'Awaiting Response' },
                { id: AmlStatus.Approved, name: 'Approved' },
              ]}
              validate={[required('AML status is required')]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
