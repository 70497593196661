import { Button, ShowActionsProps, TopToolbar } from 'react-admin';
import React from 'react';
import { Create, KeyboardBackspace } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { LimitedPartnerType } from '../api';

export interface LimitedPartnerShowActionsProps extends ShowActionsProps {
  companyId: string;
  variant: LimitedPartnerType;
}

export const LimitedPartnerShowActions: React.FC<
  LimitedPartnerShowActionsProps
> = (props) => {
  return (
    <TopToolbar {...props}>
      <Button
        component={Link}
        style={{ textTransform: 'none' }}
        to={{
          pathname: `/companies/${props.companyId}/show/2`,
        }}
        label='Back to company'
      >
        <KeyboardBackspace />
      </Button>
      {props.variant == LimitedPartnerType.Company ? (
        <Button
          component={Link}
          style={{ textTransform: 'none' }}
          to={{
            pathname: '/limited-partner-companies/' + props.data?.id + '/edit',
            state: {
              record: { companyId: props.companyId },
            },
          }}
          label='Edit'
        >
          <Create />
        </Button>
      ) : (
        <Button
          component={Link}
          style={{ textTransform: 'none' }}
          to={{
            pathname: '/limited-partner-people/' + props.data?.id + '/edit',
            state: {
              record: { companyId: props.companyId },
            },
          }}
          label='Edit'
        >
          <Create />
        </Button>
      )}
    </TopToolbar>
  );
};
