import React from 'react';
import {
  Button,
  Show,
  ShowActionsProps,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from 'react-admin';
import { KeyboardBackspace } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useLocationState } from "../location/useLocationState";

interface RepresentativeShowActionsProps extends ShowActionsProps {
  companyId: string;
}

const RepresentativeShowActions: React.FC<RepresentativeShowActionsProps> = (
  props
) => {
  return (
    <TopToolbar {...props}>
      <Button
        component={Link}
        style={{ textTransform: 'none' }}
        to={{
          pathname: `/companies/${props.companyId}/show/3`,
        }}
        label='Back to company'
      >
        <KeyboardBackspace />
      </Button>
    </TopToolbar>
  );
};

export const RepresentativeShow: React.FC<ShowProps> = (props: ShowProps) => {
  const location = useLocationState();
  const companyId = location.state.record.companyId;

  return (
    <Show
      {...props}
      actions={<RepresentativeShowActions companyId={companyId} />}
      title='Representatives'
    >
      <SimpleShowLayout>
        <TextField source='email' label='Email' />
        <TextField source='firstName' label='First Name' />
        <TextField source='lastName' label='Last Name' />
        <TextField source='phone' label='Phone' />
      </SimpleShowLayout>
    </Show>
  );
};
