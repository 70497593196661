import React from "react";
import {
  ArrayInput,
  Create,
  CreateProps,
  email,
  RedirectionSideEffect,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Record as RaRecord,
} from "react-admin";

import { phoneNumberValidator } from "../../util/phoneNumber";

export interface LimitedPartnerCompanyCreateProps extends CreateProps {
  companyTransform: (data: RaRecord) => RaRecord | Promise<RaRecord>;
  redirect: RedirectionSideEffect;
}

export const LimitedPartnerCompanyCreate: React.FC<
  LimitedPartnerCompanyCreateProps
> = (props: LimitedPartnerCompanyCreateProps) => {
  return (
    <Create
      {...props}
      basePath=""
      resource="limited-partner-companies"
      transform={props.companyTransform}
      title="Add company LP"
    >
      <SimpleForm redirect={props.redirect}>
        <ArrayInput source="companies">
          <SimpleFormIterator disableReordering>
            <TextInput
              label="Company name"
              source="companyName"
              validate={[required("Company name is required")]}
            />
            <TextInput
              label="Registry Code"
              source="registryCode"
              type="number"
            />
            <ArrayInput
              source="representatives"
              label="Representatives"
              validate={required("At least one representative is required")}
            >
              <SimpleFormIterator disableReordering>
                <TextInput
                  source="email"
                  label="Email"
                  validate={[
                    required("The email of GP is required"),
                    email("Invalid email"),
                  ]}
                />
                <TextInput
                  source="firstName"
                  label="First name"
                  validate={[required("First name is required")]}
                />
                <TextInput
                  source="lastName"
                  label="Last name"
                  validate={[required("Last name is required")]}
                />
                <TextInput
                  source="phone"
                  label="Phone"
                  validate={[phoneNumberValidator]}
                />
                <TextInput
                  source="legalPowers"
                  label="Legal powers"
                  validate={[required("Legal powers is a required field")]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
