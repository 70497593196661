import React from 'react';
import {
  Create,
  CreateProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { ServiceType } from '../api';
import { useLocation } from 'react-router';
import { InvestmentsForm } from './InvestmentsForm';
import { CurrencyInput } from '../util/inputs/CurrencyInput';
import { fundMutationTransform } from './fundMutationTransform';

interface LocationState {
  record: {
    companyId: string;
  };
}

export const FundCreate: React.FC<CreateProps> = (props: CreateProps) => {
  const location = useLocation<LocationState>();
  const companyId =
    location.state && location.state.record.companyId
      ? location.state.record.companyId
      : undefined;

  const redirect = companyId ? `/companies/${companyId}/show/1` : false;

  return (
    <Create transform={fundMutationTransform} {...props} title='Add new fund'>
      <SimpleForm redirect={redirect}>
        <TextInput
          source='name'
          validate={[required('Fund name is required')]}
        />
        <CurrencyInput source='fundSize' />
        <SelectInput
          source='serviceType'
          choices={[
            { id: ServiceType.FundStructure, name: 'Fund Structure' },
            {
              id: ServiceType.FundStructurePortfolioManagement,
              name: 'Fund Structure Portfolio Management',
            },
          ]}
          validate={[required('Service type is required')]}
        />
        <InvestmentsForm />
      </SimpleForm>
    </Create>
  );
};
