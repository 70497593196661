/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * information required to add info to the registry of the fund (LP is a company)
 * @export
 * @interface ApiCompanyRegistry
 */
export interface ApiCompanyRegistry {
    /**
     * 
     * @type {string}
     * @memberof ApiCompanyRegistry
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompanyRegistry
     */
    residency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompanyRegistry
     */
    taxResidency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompanyRegistry
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompanyRegistry
     */
    contactEmail: string;
    /**
     * 
     * @type {number}
     * @memberof ApiCompanyRegistry
     */
    totalCommitment: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCompanyRegistry
     */
    paidInCapital: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCompanyRegistry
     */
    callableCapital?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiCompanyRegistry
     */
    bankAccount: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiCompanyRegistry
     */
    agreementSigningDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiCompanyRegistry
     */
    firstClosingDate: Date;
}

export function ApiCompanyRegistryFromJSON(json: any): ApiCompanyRegistry {
    return ApiCompanyRegistryFromJSONTyped(json, false);
}

export function ApiCompanyRegistryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCompanyRegistry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'residency': json['residency'],
        'taxResidency': json['taxResidency'],
        'address': json['address'],
        'contactEmail': json['contactEmail'],
        'totalCommitment': json['totalCommitment'],
        'paidInCapital': json['paidInCapital'],
        'callableCapital': !exists(json, 'callableCapital') ? undefined : json['callableCapital'],
        'bankAccount': json['bankAccount'],
        'agreementSigningDate': (new Date(json['agreementSigningDate'])),
        'firstClosingDate': (new Date(json['firstClosingDate'])),
    };
}

export function ApiCompanyRegistryToJSON(value?: ApiCompanyRegistry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'residency': value.residency,
        'taxResidency': value.taxResidency,
        'address': value.address,
        'contactEmail': value.contactEmail,
        'totalCommitment': value.totalCommitment,
        'paidInCapital': value.paidInCapital,
        'callableCapital': value.callableCapital,
        'bankAccount': value.bankAccount,
        'agreementSigningDate': (value.agreementSigningDate.toISOString().substr(0,10)),
        'firstClosingDate': (value.firstClosingDate.toISOString().substr(0,10)),
    };
}

