/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlStatus,
    AmlStatusFromJSON,
    AmlStatusFromJSONTyped,
    AmlStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCreateLimitedPartnerPersonRequest
 */
export interface ApiCreateLimitedPartnerPersonRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCreateLimitedPartnerPersonRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateLimitedPartnerPersonRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateLimitedPartnerPersonRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateLimitedPartnerPersonRequest
     */
    residency: string;
    /**
     * 
     * @type {AmlStatus}
     * @memberof ApiCreateLimitedPartnerPersonRequest
     */
    amlStatus: AmlStatus;
}

export function ApiCreateLimitedPartnerPersonRequestFromJSON(json: any): ApiCreateLimitedPartnerPersonRequest {
    return ApiCreateLimitedPartnerPersonRequestFromJSONTyped(json, false);
}

export function ApiCreateLimitedPartnerPersonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCreateLimitedPartnerPersonRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'residency': json['residency'],
        'amlStatus': AmlStatusFromJSON(json['amlStatus']),
    };
}

export function ApiCreateLimitedPartnerPersonRequestToJSON(value?: ApiCreateLimitedPartnerPersonRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'residency': value.residency,
        'amlStatus': AmlStatusToJSON(value.amlStatus),
    };
}

