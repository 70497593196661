/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCompany,
    ApiCompanyFromJSON,
    ApiCompanyToJSON,
    ApiCreateCompanyRequest,
    ApiCreateCompanyRequestFromJSON,
    ApiCreateCompanyRequestToJSON,
} from '../models';

export interface RegisterCompanyRequest {
    apiCreateCompanyRequest: ApiCreateCompanyRequest;
}

/**
 * 
 */
export class OwnerApi extends runtime.BaseAPI {

    /**
     * creates a new company or fund
     */
    async registerCompanyRaw(requestParameters: RegisterCompanyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCompany>> {
        if (requestParameters.apiCreateCompanyRequest === null || requestParameters.apiCreateCompanyRequest === undefined) {
            throw new runtime.RequiredError('apiCreateCompanyRequest','Required parameter requestParameters.apiCreateCompanyRequest was null or undefined when calling registerCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCreateCompanyRequestToJSON(requestParameters.apiCreateCompanyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCompanyFromJSON(jsonValue));
    }

    /**
     * creates a new company or fund
     */
    async registerCompany(requestParameters: RegisterCompanyRequest, initOverrides?: RequestInit): Promise<ApiCompany> {
        const response = await this.registerCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
