import { Record } from 'react-admin';
import { extractNumericValue } from '../util/currency';

const sanitizeRegistryCurrencyValues = (record: Record) => ({
  ...record,
  totalCommitment: extractNumericValue(record.totalCommitment),
  paidInCapital: extractNumericValue(record.paidInCapital),
});

export const fundMutationTransform = (data: Record): Record => {
  return {
    ...data,
    fundSize: data.fundSize && extractNumericValue(data.fundSize),
    personRegistry: data.personRegistry?.map(sanitizeRegistryCurrencyValues),
    companyRegistry: data.companyRegistry?.map(sanitizeRegistryCurrencyValues),
    investments: data.investments?.map((it: Record) => ({
      ...it,
      fairValue: extractNumericValue(it.fairValue),
    })),
  };
};
