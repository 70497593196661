import React from 'react';
import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { CompanyRepresentativeForm } from './CompanyRepresentativeForm';

export const CompanyCreate: React.FC<CreateProps> = (props: CreateProps) => {
  return (
    <Create {...props} title='Add new company'>
      <SimpleForm>
        <TextInput
          source='companyData.name'
          label='Name'
          validate={[required('Company name is required')]}
        />
        <TextInput
          source='companyData.address'
          label='Address'
          validate={[required('Address is required')]}
        />
        <TextInput
          source='companyData.registryCode'
          label='Registry code'
          validate={[required('Registry code is required')]}
        />
        <CompanyRepresentativeForm />
      </SimpleForm>
    </Create>
  );
};
