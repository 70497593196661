/* eslint-disable */

const awsmobile = {
  "aws_project_region": "eu-central-1",
  "aws_cognito_identity_pool_id": "eu-central-1:26e03595-3087-4b7e-845f-2097161bce83",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_94NEKRKJp",
  "aws_user_pools_web_client_id": "7798skkjjlmoi376b02gj6jlii",
  "oauth": {},
};

export default awsmobile;
