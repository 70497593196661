/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlStatus,
    AmlStatusFromJSON,
    AmlStatusFromJSONTyped,
    AmlStatusToJSON,
    ApiAdminCompany,
    ApiAdminCompanyFromJSON,
    ApiAdminCompanyFromJSONTyped,
    ApiAdminCompanyToJSON,
    ApiVeriffStatus,
    ApiVeriffStatusFromJSON,
    ApiVeriffStatusFromJSONTyped,
    ApiVeriffStatusToJSON,
    RoleType,
    RoleTypeFromJSON,
    RoleTypeFromJSONTyped,
    RoleTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiInvestorProfile
 */
export interface ApiInvestorProfile {
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    residency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    phone?: string;
    /**
     * 
     * @type {AmlStatus}
     * @memberof ApiInvestorProfile
     */
    amlStatus?: AmlStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    veriffId?: string;
    /**
     * 
     * @type {ApiVeriffStatus}
     * @memberof ApiInvestorProfile
     */
    veriffStatus?: ApiVeriffStatus;
    /**
     * 
     * @type {Array<RoleType>}
     * @memberof ApiInvestorProfile
     */
    roles: Array<RoleType>;
    /**
     * 
     * @type {Array<ApiAdminCompany>}
     * @memberof ApiInvestorProfile
     */
    companies?: Array<ApiAdminCompany>;
}

export function ApiInvestorProfileFromJSON(json: any): ApiInvestorProfile {
    return ApiInvestorProfileFromJSONTyped(json, false);
}

export function ApiInvestorProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInvestorProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'residency': !exists(json, 'residency') ? undefined : json['residency'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'amlStatus': !exists(json, 'amlStatus') ? undefined : AmlStatusFromJSON(json['amlStatus']),
        'veriffId': !exists(json, 'veriffId') ? undefined : json['veriffId'],
        'veriffStatus': !exists(json, 'veriffStatus') ? undefined : ApiVeriffStatusFromJSON(json['veriffStatus']),
        'roles': ((json['roles'] as Array<any>).map(RoleTypeFromJSON)),
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(ApiAdminCompanyFromJSON)),
    };
}

export function ApiInvestorProfileToJSON(value?: ApiInvestorProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'address': value.address,
        'residency': value.residency,
        'phone': value.phone,
        'amlStatus': AmlStatusToJSON(value.amlStatus),
        'veriffId': value.veriffId,
        'veriffStatus': ApiVeriffStatusToJSON(value.veriffStatus),
        'roles': ((value.roles as Array<any>).map(RoleTypeToJSON)),
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(ApiAdminCompanyToJSON)),
    };
}

