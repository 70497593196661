import React from 'react';
import {
  Edit,
  EditActionsProps,
  EditProps,
  SimpleForm,
  TextInput,
  TopToolbar,
  useRedirect,
  SelectInput,
  required,
  Record,
} from 'react-admin';
import { AmlStatus } from '../../api';
import { LimitedPartnerShowButton } from '../LimitedPartnerShowButton';
import { useLocationState } from "../../location/useLocationState";

interface LimitedPartnerPersonEditActionsProps extends EditActionsProps {
  companyId: string;
}

const LimitedPartnerPersonEditActions: React.FC<
  LimitedPartnerPersonEditActionsProps
> = (props) => {
  return (
    <TopToolbar {...props}>
      <LimitedPartnerShowButton
        companyId={props.companyId}
        record={{ ...(props.data as Record), type: 'person' }}
      />
    </TopToolbar>
  );
};

export const LimitedPartnerPersonEdit: React.FC<EditProps> = (
  props: EditProps
) => {
  const redirect = useRedirect();
  const location = useLocationState();
  const companyId = location.state.record.companyId;

  return (
    <Edit
      {...props}
      title='Edit limited partner information'
      undoable={false}
      onSuccess={() => {
        redirect(
          'show',
          '/limited-partner-people',
          props.id,
          {},
          location.state
        );
      }}
      actions={
        <LimitedPartnerPersonEditActions companyId={companyId} {...props} />
      }
    >
      <SimpleForm>
        <TextInput source='firstName' label='First name' />
        <TextInput source='lastName' label='Last name' />
        <TextInput source='residency' label='Residency' />
        <SelectInput
          source='amlStatus'
          choices={[
            { id: AmlStatus.AwaitingPrefill, name: 'Awaiting Prefill' },
            { id: AmlStatus.Prefilled, name: 'Prefilled' },
            { id: AmlStatus.AwaitingResponse, name: 'Awaiting Response' },
            { id: AmlStatus.Approved, name: 'Approved' },
          ]}
          validate={[required('AML status is required')]}
        />
      </SimpleForm>
    </Edit>
  );
};
