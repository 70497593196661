import { Button, Record, ShowButtonProps } from 'react-admin';
import React from 'react';
import { Visibility } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export interface LimitedPartnerShowButtonProps extends ShowButtonProps {
  companyId: string;
}

export const LimitedPartnerShowButton: React.FC<
  LimitedPartnerShowButtonProps
> = ({ record, companyId }: LimitedPartnerShowButtonProps) => {
  const resourcePath =
    (record as Record).type === 'person'
      ? '/limited-partner-people/'
      : '/limited-partner-companies/';

  return (
    <Button
      component={Link}
      style={{ textTransform: 'none' }}
      to={{
        pathname: resourcePath + record?.id + '/show',
        state: { record: { companyId } },
      }}
      label='View'
    >
      <Visibility />
    </Button>
  );
};
