import React from 'react';
import { Create, CreateProps, SimpleForm } from 'react-admin';
import { useLocation } from 'react-router';
import { CompanyRepresentativeForm } from '../companies/CompanyRepresentativeForm';

interface LocationState {
  record: {
    companyId: string;
  };
}

export const RepresentativeCreate: React.FC<CreateProps> = (
  props: CreateProps
) => {
  const location = useLocation<LocationState>();
  const companyId =
    location.state && location.state.record.companyId
      ? location.state.record.companyId
      : undefined;

  const redirect = companyId ? `/companies/${companyId}/show/3` : false;

  return (
    <Create {...props} title='Add new representative'>
      <SimpleForm redirect={redirect}>
        <CompanyRepresentativeForm />
      </SimpleForm>
    </Create>
  );
};
