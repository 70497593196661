import React from 'react';
import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import { LimitedPartnerShowActions } from '../LimitedPartnerShowActions';
import { LimitedPartnerType } from '../../api';
import { useLocationState } from "../../location/useLocationState";

export const LimitedPartnerCompanyShow: React.FC<ShowProps> = (
  props: ShowProps
) => {
  const location = useLocationState();
  const companyId = location.state.record.companyId;

  return (
    <Show
      {...props}
      actions={
        <LimitedPartnerShowActions
          companyId={companyId}
          variant={LimitedPartnerType.Company}
        />
      }
      title='Limited partner'
    >
      <SimpleShowLayout>
        <TextField source='name' label='Name' />
        <TextField source='registryCode' label='Registry Code' />
        <TextField source='amlStatus' label='AML Status' />
      </SimpleShowLayout>
    </Show>
  );
};
