import { Validator } from 'react-admin';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
});

export const format = (value: number | string): string => {
  return formatter.format(Number(value));
};

export const currencyValidator: Validator = (value: string): string | null => {
  if ((value !== undefined) && (value != "") && isNaN(Number(value))) {
    return 'Fund size should only contain numbers';
  } else {
    return null;
  }
};

export const extractNumericValue = (value: string | null): string | null => {
  if (value == null) {
    return null;
  }
  return value.toString().replaceAll('€', '').replaceAll(',', '');
};
