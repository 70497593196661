import React, { useState } from "react";
import {
  ArrayField,
  Datagrid,
  Show,
  Button,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TitleProps,
  ShowActionsProps,
  TopToolbar,
  DateField,
  FunctionField,
} from "react-admin";
import { Link } from "react-router-dom";
import { KeyboardBackspace, Create } from "@material-ui/icons";
import { CurrencyField } from "../util/fields/CurrencyField";
import { useLocationState } from "../location/useLocationState";

const FundTitle: React.FC<TitleProps> = ({ record }) => {
  return <span>{record ? `${record.name}` : "Fund"} dashboard</span>;
};

interface FundDashboardActionsProps extends ShowActionsProps {
  companyId: string;
  limitedPartnerPeople: any[];
  limitedPartnerCompanies: any[];
}

const FundDashboardActions: React.FC<FundDashboardActionsProps> = (props) => {
  return (
    <TopToolbar {...props}>
      <Button
        component={Link}
        style={{ textTransform: "none" }}
        to={{
          pathname: `/companies/${props.companyId}/show/1`,
        }}
        label="Back to company"
      >
        <KeyboardBackspace />
      </Button>
      <Button
        component={Link}
        style={{ textTransform: "none" }}
        to={{
          pathname: "/funds/" + props.data?.id + "/edit",
          state: {
            record: {
              fundId: props.data?.id,
              companyId: props.companyId,
              limitedPartnerPeople: props.limitedPartnerPeople,
              limitedPartnerCompanies: props.limitedPartnerCompanies,
            },
          },
        }}
        label="Edit"
      >
        <Create />
      </Button>
    </TopToolbar>
  );
};

type TabValues = "info" | "investments" | "registry";

export const FundDashboard: React.FC<ShowProps> = (props: ShowProps) => {
  const [selectedTabValue, setSelectedTab] = useState<TabValues>("info");

  const location = useLocationState();
  const state = location.state.record;
  const companyId = state.companyId;
  const limitedPartnerPeople = state.limitedPartnerPeople;
  const limitedPartnerCompanies = state.limitedPartnerCompanies;

  const onTabChangeHandler = (value: TabValues) => {
    setSelectedTab(value);
  };
  return (
    <Show
      {...props}
      title={<FundTitle />}
      actions={
        <FundDashboardActions
          companyId={companyId}
          limitedPartnerPeople={limitedPartnerPeople}
          limitedPartnerCompanies={limitedPartnerCompanies}
        />
      }
    >
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="fullWidth" value={selectedTabValue} />
        }
      >
        <Tab
          label="Fund information"
          style={{ textTransform: "none" }}
          value="info"
          onClick={() => onTabChangeHandler("info")}
        >
          <TextField source="name" />
          <CurrencyField source="fundSize" addLabel label="Fund size" />
          <TextField source="symbol" />
          <TextField source="serviceType" />
        </Tab>
        <Tab
          label="Investments"
          style={{ textTransform: "none" }}
          value="investments"
          onClick={() => onTabChangeHandler("investments")}
        >
          <ArrayField source="investments" fieldKey="id">
            <Datagrid>
              <TextField source="name" />
              <CurrencyField source="fairValue" />
              <TextField source="sector" />
              <TextField source="instrument" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab
          label="Registry"
          style={{ textTransform: "none" }}
          value="registry"
          onClick={() => onTabChangeHandler("registry")}
        >
          <ArrayField source="personRegistry" addLabel fieldKey="id">
            <Datagrid>
              <FunctionField
                label="Person"
                render={(record: any) =>
                  `${
                    limitedPartnerPeople.find(
                      (it: any) => it.id == record.personId
                    ).email
                  }`
                }
              />
              <TextField source="taxResidency" />
              <TextField source="address" />
              <CurrencyField source="totalCommitment" />
              <CurrencyField source="paidInCapital" />
              <CurrencyField source="callableCapital" />
              <TextField source="bankAccount" />
              <DateField source="agreementSigningDate" />
              <DateField source="firstClosingDate" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="companyRegistry" fieldKey="id">
            <Datagrid>
              <FunctionField
                label="Company"
                render={(record: any) =>
                  `${
                    limitedPartnerCompanies.find(
                      (it: any) => it.id == record.companyId
                    ).name
                  }`
                }
              />
              <TextField source="residency" />
              <TextField source="taxResidency" />
              <TextField source="address" />
              <TextField source="contactEmail" />
              <CurrencyField source="totalCommitment" />
              <CurrencyField source="paidInCapital" />
              <CurrencyField source="callableCapital" />
              <TextField source="bankAccount" />
              <DateField source="agreementSigningDate" />
              <DateField source="firstClosingDate" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
